import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51')
];

export const server_loads = [0,2,3];

export const dictionary = {
		"/": [4],
		"/about": [5],
		"/admin/categorise": [~6],
		"/admin/categorise/[dataImportIdOrAll]": [~7],
		"/admin/ocr": [~8],
		"/admin/tools": [~9],
		"/app": [~10,[2]],
		"/app/accounts": [~11,[2]],
		"/app/claim/[invitationId=integer]": [~12,[2]],
		"/app/claim/[invitationId=integer]/[claimToken]": [~13,[2]],
		"/app/common": [~14,[2]],
		"/app/help": [15,[2]],
		"/app/list": [~16,[2]],
		"/app/ocr/[productId=integer]": [~17,[2]],
		"/app/paid/[productId=integer]": [~18,[2]],
		"/app/partg": [~19,[2]],
		"/app/partn": [~20,[2]],
		"/app/partn/new": [~21,[2]],
		"/app/reject/[invitationId=integer]/[claimToken]": [~22,[2]],
		"/app/report": [~23,[2]],
		"/app/report/new": [~24,[2]],
		"/app/settings": [~25,[2]],
		"/app/settings/payments": [~26,[2]],
		"/app/settings/payments/success": [~27,[2]],
		"/app/share": [~28,[2]],
		"/app/statements": [~29,[2]],
		"/app/upgrade": [~30,[2]],
		"/blog/affordable-forensic-accounting-family-law": [~31],
		"/blog/forensic-account-for-self-represented-family-law-litigants": [~32],
		"/categories": [~33],
		"/compliance": [34],
		"/dev/testimonial": [35],
		"/faq": [36],
		"/financial-statement-kit": [37],
		"/integration/code": [~38],
		"/leap/redirect": [~39,[3]],
		"/learn/partn": [40],
		"/learn/report": [41],
		"/legals": [42],
		"/login": [~43],
		"/login/badlink": [45],
		"/login/[token=uuid]": [~44],
		"/ocr": [46],
		"/privacy": [47],
		"/register": [~48],
		"/support": [49],
		"/support/smokeball": [50],
		"/survey/[type=productType]": [51]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';