import { dev } from '$app/environment';
import { env } from '$env/dynamic/public';
import * as Sentry from '@sentry/sveltekit';

if (!dev && !env.PUBLIC_IS_LOCAL && !env.PUBLIC_IS_TESTING) {
  Sentry.init({
    dsn: 'https://e98aedb1428d463bb0c3eeb5b05e2303@o4505231698231296.ingest.sentry.io/4505231770976256',
    tracesSampleRate: 1.0,
    // For instance, initialize Session Replay:
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.Replay()],
    release:
      (env.PUBLIC_ENVIRONMENT || 'unknown_environment') +
      ':' +
      (env.PUBLIC_COMMIT_ID || 'unknown_commit'),
  });
}

export const handleError = dev ? () => null : Sentry.handleErrorWithSentry();
